// Contacto.js
import React from 'react';

function Contacto() {
  return (
   
    <div className='contact-container'>
      <p className='texto2'>¡Sumate a transformar la Argentina!</p>
      

   <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeOQF7pmb8-yZE9V87wk3EeLqjTvkh7Odp_KapFrFpXF3aMbA/viewform?embedded=true" width="100%" height="605" frameborder="0" marginheight="0" marginwidth="0"  title="Cuestionario de Contacto">Cargando…</iframe>
    </div>
  );
}

export default Contacto;
